import fetch from 'isomorphic-unfetch';
import { getConfig } from 'config/get-config';
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  defaultDataIdFromObject
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { authService } from 'clients/auth';
import get from 'lodash/get';
import cookie from 'cookie';
import jwtDecode from 'jwt-decode';
import { getWithApollo } from './withApollo';
import { bugsnagClient } from '../../clients/bugsnag';

const isServer = typeof window === 'undefined';

const { GRAPHQL_ENDPOINT, BUILD_NUMBER, ACG_ENV } = getConfig();

const checkIsTokenExpired = token => {
  let decoded = {};

  try {
    decoded = jwtDecode(token);
  } catch (err) {
    return {
      isValid: false,
      isExpired: false
    };
  }

  const expiry = get(decoded, 'exp', '');

  if (!expiry) {
    return {
      isValid: false,
      isExpired: false
    };
  }

  const now = Math.floor(new Date().getTime() / 1000); // convert date to seconds
  const isExpired = now > expiry;
  return {
    isValid: true,
    isExpired
  };
};

const getToken = headers => {
  if (!isServer) {
    return authService.getToken().catch(err => {
      console.log('Could not retrieve token for gql request', err);
      return '';
    });
  }

  const cookies = get(headers, 'cookie', '');

  const token = get(cookie.parse(cookies), 'auth0_token', '');

  if (token && checkIsTokenExpired(token).isExpired) {
    console.log(`Token: ${token} has expired.`);
    return '';
  }

  return token;
};

const attachAuth = headers => async () => {
  const token = await getToken(headers);

  return {
    headers: {
      authorization: `Bearer ${token}`,
      'apollographql-client-name': `profile-${ACG_ENV}`,
      'apollographql-client-version': `${BUILD_NUMBER}`
    }
  };
};

const initializeCache = () =>
  new InMemoryCache({
    dataIdFromObject: object => {
      // eslint-disable-next-line
      switch (object.__typename) {
        case 'CertificationType':
          return object.id === 'other'
            ? `CertificationType:other|${object.title}`
            : defaultDataIdFromObject(object);
        default:
          return defaultDataIdFromObject(object);
      }
    }
  });

const createApolloClient = ({ initialState = {}, headers = {} }) => {
  const authLink = () => setContext(attachAuth(headers));
  const httpLink = new HttpLink({
    credentials: 'same-origin',
    uri: GRAPHQL_ENDPOINT,
    fetch
  });

  const errorLoggingLink = onError(
    ({ operation, networkError, graphQLErrors }) => {
      if (networkError) {
        bugsnagClient.notify(networkError, {
          context: `ApolloLink error for query ${operation &&
            operation.operationName}${isServer ? ' (SSR)' : ''}`,
          metaData: {
            networkErrorResultMessage:
              networkError.result && networkError.result.Message
          }
        });
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          bugsnagClient.notify(
            new Error(`GraphQL error: Message: ${message}`),
            {
              context: `GraphQLError${isServer ? ' (SSR)' : ''}`,
              metaData: {
                locations,
                path
              }
            }
          );
        });
      }
    }
  );

  return new ApolloClient({
    ssrMode: isServer,
    link: ApolloLink.from([authLink(), errorLoggingLink, httpLink]),
    cache: initializeCache().restore(initialState)
  });
};

export { attachAuth, createApolloClient };
const withApollo = getWithApollo(createApolloClient);
export default withApollo;
